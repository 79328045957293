import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import './assets/styles/_index.scss';
import CustomSlider from "../CustomSlider/CustomSlider";
import { DivOverlay } from "leaflet";
import ScrollAnimation from "react-animate-on-scroll";
const { ContentModule } = require("@starberry/gatsby-theme-utils/Modules/ContentModule")
const { ImageModule } = require("@starberry/gatsby-theme-utils/Modules/ImageModule")

const EventWithIcon = (props) => {

    return (
        <section className={`icon-event slider attend section_item`} id="why-attend">
            <Container>
                <Row>
                    <Col lg={12}>
                    <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={100} offset={100}>
                        <div className="event-top-block">
                            {props.title && <h2>{props.title}</h2>}

                            {(props.description?.data?.description || (props.isPreviewEnabled && props.description)) && 
                            <ContentModule Content={props.description?.data?.description || props.description} />}
                        </div>
                        </ScrollAnimation>
                        {props?.icon_module?.length > 0 ?
                        <div className="event-icon-slider">
                                {props?.icon_module?.map((item, i) => {
                                     var imagename = "event.icon_section_image.image";

                                     let processedImages = JSON.stringify({});
                                     if (props?.imagetransforms) {
                                         processedImages = props?.imagetransforms;
                                     }
                                   
                                    return (
                                        <ScrollAnimation className="icon-item" animateIn="animate__slideInUp" animateOnce delay={i*100} offset={100}>
                                        {/* <div className="icon-item"> */}
                                        {item?.image?.url&&
                                            <img src={item?.image?.url} alt={item?.image?.alternativeText ? item?.image?.alternativeText : item.title} />
                                        }
                                            {/* <ImageModule ImageSrc={item?.image} altText={`${item?.image?.alternativeText ? item?.image?.alternativeText : item.title ? item.title : ""} icon`} imagetransforms={processedImages} renderer="pic-src" imagename={imagename} strapi_id={props?.id} classNames="img-fluid banner-img" /> */}
                                            <div className="title">{item?.title}</div>
                                            {(item.description?.data?.description || (props.isPreviewEnabled && item?.description)) && 
                                            <ContentModule Content={item.description?.data?.description || item.description} />}
                                        </ScrollAnimation>
                                    )
                                })}
                            </div>
: null}

                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default EventWithIcon