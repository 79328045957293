import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import './assets/styles/_index.scss';
import CustomSlider from "../CustomSlider/CustomSlider";
import CustomSliderCampEvent from "../CustomSlider/CustomSliderCampEvent";
import useDeviceMedia from "../../hooks/useDeviceMedia";
import ScrollAnimation from "react-animate-on-scroll";
import GGFXImage from "../../modules/GGFXImage";
const { ContentModule } = require("@starberry/gatsby-theme-utils/Modules/ContentModule")
const { ImageModule } = require("@starberry/gatsby-theme-utils/Modules/ImageModule")

const EventSpeakers = (props) => {

    const {isMobile, isTabletOnly}=useDeviceMedia()

    return (
        <section className={`icon-event slider speakers section_item`} id="speakers">
            <Container>
                <Row>
                    <Col lg={12}>
                    <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={100} offset={100}>
                        <div className="event-top-block">
                            {props.title && <h2>{props.title}</h2>}

                            {(props.description?.data?.description || (props.isPreviewEnabled && props.description))&& 
                            <ContentModule Content={props.description?.data?.description || props.description} />
                            }
                        </div>
                        </ScrollAnimation>
                        {props?.icon_module?.length > 0 ?
                        <CustomSliderCampEvent slidecount={isMobile?"2":isTabletOnly?"2":props?.slide_count} className={`event-icon-slider ${props?.slide_count==="3"?"speaker-count_3":""}`}>
                                {props?.icon_module?.map((item, i) => {
                                     var imagename = "event.icon_section_image.speakersimage";

                                     let processedImages = JSON.stringify({});
                                     if (props?.imagetransforms) {
                                         processedImages = props?.imagetransforms;
                                     }
                                   
                                    return (
                                        <ScrollAnimation className="icon-item" animateIn="animate__slideInUp" animateOnce delay={i*100} offset={100}>
                                            <GGFXImage
                                                ImageSrc={item?.image}
                                                altText={`${
                                                item?.image?.alternativeText
                                                    ? item?.image?.alternativeText
                                                    : item.title
                                                }`}
                                                imagetransforms={props?.ggfx_results}
                                                renderer="srcSet"
                                                imagename={imagename}
                                                strapiID={props?.id}
                                                classNames="banner-img"
                                            />
                                            <div className="title">{item?.title}</div>
                                            {(item.description || (props.isPreviewEnabled && item.description)) && 
                                            <ContentModule Content={item.description?.data?.description || item.description} />}
                                        </ScrollAnimation>
                                    )
                                })}
                            </CustomSliderCampEvent>
: null}

                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default EventSpeakers