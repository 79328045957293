import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import './assets/styles/_index.scss';
import CustomSlider from "../CustomSlider/CustomSlider";
import ScrollAnimation from "react-animate-on-scroll";
const { ContentModule } = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const EventQuote = (props) => {

    return (
        <section className={`quote-event slider`}>
            <Container>
                <Row>
                    <Col lg={12}>
                        <div className="quote"/>
                        {props?.quote_item?.length > 0 ?
                        <CustomSlider isQuote={true} slidecount={1} className="event-quote-event-slider">
                                {props?.quote_item?.map((item, i) => {
                                    return (
                                        <ScrollAnimation className="quote-event-item" animateIn="animate__slideInUp" animateOnce delay={i*100} offset={100}>
                                        {/* <div className="quote-event-item"> */}
                                           
                                        {(item.quotes?.data?.quotes || (props.isPreviewEnabled && item?.quotes)) 
                                            && <ContentModule Content={item.quotes?.data?.quotes || item?.quotes} />
                                            }
                                            <div className="name">{item?.name}</div>
                                            <div className="designation">{item?.designation}</div>
                                            
                                        </ScrollAnimation>
                                    )
                                })}
                            </CustomSlider>
: null}

                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default EventQuote