import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import './assets/styles/_index.scss';
import ScrollAnimation from "react-animate-on-scroll";
const { ContentModule } = require("@starberry/gatsby-theme-utils/Modules/ContentModule")


const EventAgenda = (props) => {
    
    return (
        <section className={`agenda-event section_item`} id="agenda">
            <Container>
                <Row>
                    <Col lg={12}>
                    <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={100} offset={100}>
                        <div className="event-top-block">
                            {props.title && <h2>{props.title}</h2>}

                            {(props.content?.data?.content || (props.isPreviewEnabled && props.content))
                                && <ContentModule Content={props.content?.data?.content || props.content} />
                            }
                        </div>
                        </ScrollAnimation>
                        {props?.add_time?.length > 0 &&
                            <div className="agenda-details">
                                <h3>{props?.timesheet_title}</h3>
                                {props?.add_time?.map((item, i) => {
                                    return (
                                        <ScrollAnimation className="agenda-list" animateIn="animate__slideInUp" animateOnce delay={100} offset={100}>
                                        {/* <div className="agenda-list"> */}
                                        {item?.time&&
                                            <p className="time">{item?.time}</p>
                                        }
                                        {item?.title&&
                                            <p className="title">{item?.title}</p>
                                        }
                                        </ScrollAnimation>
                                        
                                    )
                                })}
                            </div>
                        }


                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default EventAgenda