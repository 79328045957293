import React, { useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import PlayVideo from "../PlayVideo/PlayVideo"
import Map from "../PropertyMap/Map"
import "./TwoColBlock.scss"
import YoutubePlayer from "../YoutubePlayer/YoutubePlayer"
import GGFXImage from "../../modules/GGFXImage"
const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")
const {
  ImageModule,
} = require("@starberry/gatsby-theme-utils/Modules/ImageModule")
const {
  PreviewCTALink,
} = require("@starberry/gatsby-theme-utils/Modules/PreviewCTALinkModule")
const TwoColBlock = props => {
  var imagename = props?.valuation
    ? "page.image_content_image.image"
    : "event.image_content_image.image"

  const [isPlay, setPlay] = useState(false)

  let processedImages = JSON.stringify({})
  if (props?.imagetransforms) {
    processedImages = props?.imagetransforms?.image_content_image_Transforms
  }

  const loc = props.locationblock ? props?.left_right_block[0] : ""

  const locationInfo = {
    latitude: parseFloat(loc.latitude),
    longitude: parseFloat(loc.longitude),
  }

  return props?.left_right_block?.map((item, i) => {
    return (
      <section
        id={props.locationblock ? "" : "about"}
        className={`tile-block-wrapper section_item ${
          props.valuation ? "valuation" : ""
        } position-relative ${
          item?.layout === "right" ? "tile-right" : "tile-left"
        }`}
      >
        <Container>
          <Row
            className={`d-flex align-items-center ${
              item?.layout === "right" ? "flex-row-reverse" : ""
            }`}
          >
            <Col xl={6}>
              {props.locationblock && locationInfo.latitude ? (
                <div className="map-location event">
                  <Map property_details_map_data={locationInfo} />
                </div>
              ) : 
              item?.video_url?
              <div className="tile-img-wrapper img-zoom">
              <YoutubePlayer video_url={item?.video_url}/>
              </div>
              :
              (
                <div className="tile-img-wrapper img-zoom">
                  <GGFXImage
                    ImageSrc={item?.image}
                    altText={item?.image?.alternativeText}
                    imagetransforms={props?.ggfx_results}
                    renderer="srcSet"
                    imagename={imagename}
                    strapiID={props?.id}
                  />
                  {props?.left_right_block.length > 0 &&
                  props?.left_right_block[0]?.video_url ? (
                    <button
                      onClick={() => setPlay(true)}
                      className="play-button"
                    >
                      <i className="icon video-filled-icon" />
                    </button>
                  ) : null}
                </div>
              )}
            </Col>
            <Col xl={1}></Col>
            <Col xl={5}>
              {item?.title && <h3>{item.title}</h3>}
              {(item?.description?.data?.description || (props.isPreviewEnabled&&item?.description))&& (
                <p className="description">
                  <ContentModule
                    Content={item.description?.data?.description || item?.description}
                  />
                </p>
              )}

              {item?.cta_label ? (
                <PreviewCTALink
                  class="button button-outline-black"
                  link={item.menu? item.menu : { external_link: item?.custom_link }}
                  title={item.cta_label}
                />
              ) : null}
            </Col>
          </Row>
        </Container>
        <PlayVideo
          isOpen={isPlay}
          stopPlay={setPlay}
          videoId=""
          isCloseFunction={setPlay}
          videourl={item?.video_url}
          htmlink={""}
        />

      </section>
    )
  })
}

export default TwoColBlock
